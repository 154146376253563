var Platform = {
  pageContext: {},

  chartColors: {
    blue: 'rgb(54, 162, 235)',
    gray: 'rgb(201, 203, 207)',
    green: 'rgb(75, 192, 192)',
    orange: 'rgb(255, 159, 64)',
    purple: 'rgb(153, 102, 255)',
    red: 'rgb(255, 99, 132)',
    yellow: 'rgb(255, 205, 86)'
  },

  formatCurrency: function (value) {
    return '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  },

  initComingSoonLinks: function($) {
    $('a.coming-soon').click(function(e) {
      e.preventDefault();
      alert('Coming soon!');
    });
  },

  initClickableInputWrappers: function($) {
    $('.clickable-input-wrapper').click(function(e) {
      var targetInput = $(this);

      targetInput.find('input').each(function() {
        var inputType = $(this).attr('type');

        if (inputType === 'radio') {
          $(this).prop('checked', true);
        } else {
          $(this).click();
        }
      });
    });
  },

  initClickableRows: function($) {
    $(".clickable-row").click(function() {
      window.location = $(this).data("href");
    });
  },

  initExternalLinks: function($) {
    $("a[rel='external']").each(function() {
      $(this).attr("target", "_blank");
      $(this).not(':has(i,img)').append(' <i class="fas fa-fw fa-external-link-alt"></i>');
    });
  },

  initCodeFormatting: function($) {
    document.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightBlock(block);
    });
  },

  isBlank: function (value) {
      return ((typeof value === 'undefined') ||
          (value === null) ||
          (value.trim() === ''));
  },

  isPresent: function (value) {
    return !Platform.isBlank(value);
  }
}

jQuery(document).ready(function ($) {
  Platform.initClickableInputWrappers($);
  Platform.initClickableRows($);
  Platform.initCodeFormatting($);
  Platform.initComingSoonLinks($);
  Platform.initExternalLinks($);
});

window.Platform = Platform;
