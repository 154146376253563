// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import 'bootstrap'
import '../stylesheets/application'

import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

import select2 from "select2"
require("select2/dist/css/select2.css")

import moment from "moment/moment"

window.moment = moment

import Chart from 'chart.js'
window.Chart = Chart

import 'page_context'

import 'log'
import 'platform'

import $ from 'jquery'

import hljs from "highlight.js/lib/core";
import css from 'highlight.js/lib/languages/css';
import json from 'highlight.js/lib/languages/json';
import sql from 'highlight.js/lib/languages/sql';
import xml from 'highlight.js/lib/languages/xml';
import 'highlight.js/styles/dracula.css';

import "../components"

hljs.registerLanguage('css', css);
hljs.registerLanguage('json', json);
hljs.registerLanguage('sql', sql);
hljs.registerLanguage('xml', xml);

window.hljs = hljs;

require.context('../images', true);

$(($) => {
  if(typeof PageContext.initSelect2Controls === 'function') {
    Log.debug('Initializing Select2 page-specific controls ...');
    PageContext.initSelect2Controls($);
  }

  $('[data-toggle="tooltip"]').tooltip({
    delay: {
      "show": 500,
      "hide": 100
    }
  });

  $('[data-toggle="popover"]').popover()
});
