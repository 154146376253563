var Log = {
  debug: function(message) {
      this._renderLevel(message, 'debug');
  },

  error: function(message) {
      this._renderLevel(message, 'error');
  },

  hasConsole: function() {
      return window.console && (typeof(window.console.log) === 'function');
  },

  info: function(message) {
      this._renderLevel(message, 'info');
  },

  displayLevel: (typeof(window.logLevel) !== 'undefined') ? window.logLevel : 'debug',

  prefix: 'Platform > ',

  render: function(message) {
      if (this.hasConsole()) {
          console.log(new Date().toLocaleTimeString() + ' ' + this.prefix + message);
      }
  },

  setLevel: function(level) {
      this.displayLevel = level;
  },

  trace: function(message) {
      this._renderLevel(message, 'trace');
  },

  warn: function(message) {
      this._renderLevel(message, 'warn');
  },

  _levelEnabled: function(level) {
      return this._levels[level] >= this._levels[this.displayLevel];
  },

  _levels: {
      'trace' : 0,
      'debug' : 1,
      'info' : 2,
      'warn' : 3,
      'error' : 4
  },

  _renderLevel: function(message, level) {
      if (this._levelEnabled(level)) {
          this.render('[' + level.toUpperCase() + '] ' + message);
      }
  }
};

Log.debug('Civotek Platform logger initialized.');

window.Log = Log;
